import { HttpHelper } from "../../helper/http.helper";
import { Observable } from "rxjs";
import { I18nService } from "../i18n/i18n.service";
import { zh_CN } from "../i18n/languages/zh_CN";
import { en_US } from "../i18n/languages/en_US";
var GlobalService = /** @class */ (function () {
    function GlobalService(http, i18n) {
        var _this = this;
        this.http = http;
        this.i18n = i18n;
        this.locale_key = { 'zh_CN': zh_CN, 'en_US': en_US };
        this.i18n.setLocale(this.locale_key[sessionStorage.getItem('locale') || 'zh_CN']);
        this.i18n.localChange.subscribe(function (locale) {
            _this.locale = locale;
        });
    }
    Object.defineProperty(GlobalService.prototype, "$current_user", {
        get: function () {
            var user = localStorage.getItem('user');
            user = JSON.parse(user);
            return user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$eqding_user", {
        get: function () {
            var user = localStorage.getItem('eqding_user');
            user = JSON.parse(user);
            return user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$shop", {
        get: function () {
            var shop = localStorage.getItem('shop');
            shop = JSON.parse(shop);
            return shop;
        },
        set: function (shop) {
            localStorage.setItem('shop', JSON.stringify(shop));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "shop_ids", {
        get: function () {
            return this.current_project && this.current_project.product_shared_by ? [this.$shop.id, this.$current_user.company.shop_id] : [this.$shop.id];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$language_system", {
        get: function () {
            var language = localStorage.getItem('language');
            language = JSON.parse(language);
            return language;
        },
        set: function (language) {
            localStorage.setItem('language', JSON.stringify(language));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "current_project", {
        get: function () {
            var data = localStorage.getItem('current_project');
            var _current_project = JSON.parse(data);
            return _current_project;
        },
        set: function (value) {
            localStorage.setItem('current_project', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.system_languages = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('api/system_languages.json', search_params);
    };
    GlobalService.prototype.language_systems = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('api/system_languages/language_systems.json', search_params);
    };
    GlobalService.prototype.deleteImage = function (id) {
        var params = { shop_id: id };
        return this.http.AUTH_HTTP_DELETE('api/images/' + id + '.json', params);
    };
    GlobalService.prototype.ht_locations = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('api/ht_locations.json?', search_params);
    };
    Object.defineProperty(GlobalService.prototype, "dataformat", {
        get: function () {
            return sessionStorage.getItem('locale') === 'en_US' ? 'LL' : 'YYYY-MM-DD';
        },
        enumerable: true,
        configurable: true
    });
    return GlobalService;
}());
export { GlobalService };
