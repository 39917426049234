import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/operate/user.service";
import {NzMessageService} from "ng-zorro-antd";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {GlobalService} from "../../services/global.service";
import {SsoService} from "../../services/operate/sso.service";
import {DataHelper} from "../../../helper/data.helper";
import {EqdingUserService} from "../../services/eqding/eqding-user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  username
  password

  portrait

  constructor(private user_service: UserService,
              private message: NzMessageService,
              private router: Router,
              private global_service: GlobalService,
              private sso_service: SsoService,
              private active_route: ActivatedRoute,
              private data_helper: DataHelper,
              private eqding_user_service: EqdingUserService) { }

  ngOnInit() {
    this.active_route.queryParams.subscribe((params: Params) => {
      if (params.service_ticket) {
        localStorage.setItem('service_ticket', params.service_ticket);
        this.user_service.sso_sign_in(params.service_ticket).subscribe(data => {
          if (data && data.user) {
            localStorage.setItem('user_session_key', data.user.user_session_key);
            localStorage.setItem('user', JSON.stringify(data.user));

            if (this.global_service.$current_user.shops && this.global_service.$current_user.shops.length !== 0) {
              localStorage.setItem('shop', JSON.stringify(this.global_service.$current_user.shops[0]));
            }

            this.eqding_user_service.sso_sign_in(params.service_ticket).subscribe(data => {
              if (data) {
                localStorage.setItem('eqding_user', JSON.stringify(data.user));
                localStorage.setItem('eqding_user_session_key', data.user_session_key);
              }
            });
            const permissions = this.global_service.$current_user.permission;
            if (!permissions || this.data_helper.isHave(permissions, ['product', 'product_list'])) {
              this.router.navigate(['/dashboard/product']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        });
      } else {
        this.data_helper.goToSsoLogin(window.location.href);
      }
    });
  }

  login(): void {
    if (!this.username) {
      this.message.create('error', '请输入用户名')
      return
    }
    if (!this.password) {
      this.message.create('error', '请输入密码')
      return
    }
    let params = {
      username: this.username,
      password: this.password
    }

    this.user_service.login(params).subscribe(data => {

      if (data && data.status && data.status.code === '50000') {
        this.message.create('error', data.status.message[0])
      } else {
        localStorage.setItem('user_session_key', data.user.user_session_key);
        localStorage.setItem('user', JSON.stringify(data.user));
        this.router.navigate(['/dashboard/product']);
      }

      if (this.global_service.$current_user.shops && this.global_service.$current_user.shops.length !== 0) {
        localStorage.setItem('shop', JSON.stringify(this.global_service.$current_user.shops[0]));
      }

    })
  }
}
