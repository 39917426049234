import { I18nInterface } from '../i18n.interface';

export const zh_CN: I18nInterface = {
  locale: 'zh_CN',
  common: {
    unpin:  '取消置顶',
    topping:  '置顶',
    drag_error: '你不可以把父级分类拖拽到他自己下',
    role: '角色',
    log_out: '退出',
    new: '新建',
    logo: '商标',
    name: '名称',
    product_name: '产品名称',
    name_en: '英文名称',
    location: '地址',
    status: '状态',
    phone: '电话',
    language: '语言',
    create_time: '创建时间',
    update_time: '更新时间',
    option: '操作',
    search: '搜索',
    clear: '清除',
    edit: '编辑',
    delete: '删除',
    upload: '点击上传文件',
    image_name: '请使用小写字母(a-z)和数字(0-9)，单词间用“-”连接',
    active: '有效',
    inactive: '无效',
    state: '洲',
    country: '国家',
    province: '省',
    city: '城市',
    submit: '提交',
    desc: '描述',
    link: '品牌链接',
    page_total: '共',
    page_items: '条记录',
    icon: '图标',
    image: '图片',
    seq: '顺序',
    cancel: '取消',
    product_attribute: '产品属性',
    create: '添加',
    details: '详细属性',
    is_sku: '是否是SKU属性',
    is_required: '是否必填',
    is_filter: '作为过滤条件',
    is_search: '作为搜索条件',
    remove: '删除',
    product_attribute_value: '产品属性值',
    delete_confirm: '你确定删除这条数据么？',
    art_no: '货号',
    parent_art_no: '产品货号',
    brand: '品牌',
    picture: '图片',
    labels: '标签',
    add_child: '添加子产品',
    checking: '审核中',
    publish: '上架',
    version: '版本',
    resource: '关联',
    resource_name: '关联名称',
    child_confirm: '你确定添加子产品吗？',
    username: '用户名',
    password: '密码',
    password_placeholder: '请输入密码',
    real_name: '用户昵称',
    import: '导入',
    show_ordinary: '显示主站数据',
    import_product_label: '导入产品标签',
    import_brand: '导入品牌',
    import_product_category: '导入产品种类',
    create_success: '创建成功',
    update_success: '修改成功',
    delete_success: '删除成功',
    import_success: '导入成功',
    create_failed: '创建失败',
    update_failed: '修改失败',
    delete_failed: '删除失败',
    edit_change_info: '修改种类后将不会再保持原来的属性',
    user_center: '个人中心',
    nickname: '昵称',
    company: '公司',
    preview: '预览',
    site: '站点',
    category_add_hint: '请先点击提交，种类',
    portrait: '头像',
    full_name: '全称',
    email: '邮件地址',
    name_required: '请填写品类名称',
    sku_placeholder: '请选择属性值',
    sku_status: 'SKU状态',
    delete_document_confirm: '删除文件夹后，文件夹中所有的文件都将删除',
    shop_manager: '管理员',
    show_main_site: '显示主站数据',
    user_info: '用户',
    company_info: '公司',
    group_info: '集团',
    custom: '自定义',
    set_cover: '设为封面',
    cover: '封面',
    category_type: '属性类型',
    select: '下拉选择',
    input: '输入框',
    textarea: '文本域',
    video_type_error: '请上传正确的视频文件',
    must_input_error: '带*号的为必填项',
    video_specification: '支持的视频格式',
    size: '大小',
    upload_file: '上传文件',
    file_upload: '文件上传',
    file_name: '文件名',
    file_type: '文件类型',
    technical_parameter: '技术参数',
    product_category_type: '类型',
    product: '产品',
    promotion_gifts: '促销品',
    no_data: '没有查询到符合条件的记录',
    product_label: '产品标签',
    end_time: '失效日期',
    batch_update: '批量修改',
    product_config: '产品设置',
    share: '分享',
    shared_by: '通过分享',
    yes: '是',
    no: '否',
    config: '设置',
    role_list: "角色列表",
    remark: '备注',
    permission: '权限',
    save: '保存',
    password_error: '密码必须填写',
    password_length_error: '密码必须在6-20位之间',
    username_error: '用户名必须填写',
    updater: '更新者',
    file_not_upload_error: '文件尚未上传，请上传文件并等待文件上传结束',
    show_type: '显示方式',
    special_type: '特殊类型',
    blank: '无',
    power_TD: '高压',
    description: '品类介绍',
    update: '更新',
    file_big: '上传图片过大，建议上传大小750KB以内的图片'
  },
  dashboard: {
    shops: '我的站点',
    brands: '产品品牌',
    product_labels: '产品标签',
    documents: '文档管理',
    product_categories: '产品种类',
    products: '产品',
    user_management: '用户',
    sites: '网站',
    content: '新闻中心',
    classification: '新闻种类',
    news_tag: '新闻标签',
    news: '新闻',
    member: '会员中心',
    member_tag: '会员标签',
    member_management: '会员',
    my_document: '文档',
    document_management: '文档中心',
    document_category: '文档种类',
    document_type: '文档类型',
    document_language: '文档语言',
    document_label: '文档标签',
    product_center: '产品中心',
    shop_management: '站点管理',
    user_center: '个人中心',
    promotion_category: '促销品种类',
    promotion: '促销品',
    form_data: '表单数据',
    subscribe: '订阅者',
    self_service: '自助服务',
    recruitment: '招聘',
    custom_contact: '用户留言',
    website_name: '正泰电器海外网站群',
    hide_confirm: '在我的站点隐藏该分类及分类下的产品',
    show_confirm: '在我的站点显示该分类及分类下的产品',
    role: '角色列表',
    new_role: '新增角色',
    permission_manage: '权限管理',
    create_role: '新建角色',
    edit_role: '编辑角色'
  },
  product: {
    category: '种类',
    product_category: '选择产品种类',
    product_sku: '产品SKU',
    product_attribute: '填写产品属性',
    edit_product_detail: '编辑产品详情',
    choose_category: '请选择产品种类',
    choose_language: '请选择产品语言',
    sku_attribute: '产品规格SKU',
    edit_sku: '编辑SKU',

    attribute_value: '属性值',
    upload: '上传',

    price_unit: '货币单位',
    price: '售价',
    cost: '成本',

    weight_unit: '重量单位',
    weight: '净重',
    package_weight: '包装后重量',
    publish_weight: '物流毛重',

    length_unit: '长度单位',
    package_length: '包装后长',
    package_width: '包装后宽',
    package_height: '包装后高',

    volume: '体积',
    volume_weight: '体积重',

    inventory_quantity: '库存',
    sales_quantity: '销售量',
    inventory_threshold: '库存预警值',

    product_info: '产品基本信息',
    product_label: '产品标签',

    shop: '商店',
    image: '产品橱窗图',
    document: '产品文档',

    spu: '产品属性——SPU属性（非SKU属性）',
    spu_attribute: '其他属性',
    spu_attribute_name: '产品属性值',
    add_attribute: '添加',

    product_detail: '产品详细描述',
    edit_document: '编辑文档',
    new_document: '新建文件夹',

    previous: '上一步',
    next: '下一步',
    save_product: '保存',
    document_edit_error: '您还没有创建文档',
    cover_hint: '你可以拖拽图片改变展示顺序，第一张图片为封面',

    pending_release: '待发布',
    selling: '正在销售',
    off_shelf: '已下架',

    price_tr: '价格',
    inventory_tr: '库存',
    weight_tr: '重量',
    volume_tr: '体积',
    product_no: '原厂货号',
    image_check: '推荐上传图片尺寸：800*800px，图片格式：JPEG，图片大小：750KB以内；最多上传20张图片',
    image_size: '图片名称请使用小写字母(a-z)和数字(0-9)，单词间用“-”连接',
    edit_title: '产品发布',

    step_one: '第一步',
    step_two: '第二步',
    step_three: '第三步',
    add_new_sku: '添加产品规格',
    product_video: '产品视频',
    upload_video: '视频上传',
    click_upload_video: '本地视频上传',
    product_video_rule: '查看视频规范',
    copy_sku: '一键复制',
    copy_sku_hint: '拷贝第一行的数据到其他行',
    edit_sku_image: '编辑SKU图片',

    word_limit: '不能超过80个字符',

    product_sample: '产品样本',
    certificate: '认证证书',
    system_certification: '体系认证',
    test_report: '检测报告',

    promotion_title: '促销品',
    choose_promotion_category: '选择促销品种类',
    promotion_attribute: '填写促销品属性',
    edit_promotion_detail: '编辑促销品详情',
    promotion_category: '促销品种类',

    promotion_label: '促销品标签',
    promotion_name: '促销品名称',
    promotion_info: '促销品基本信息',

    promotion_img: '促销品图片',
    promotion_document: '促销品文档',
    promotion_video: '促销品视频',
    promotion_detail: '促销品详情',

    promotion_tag: 'Promotion Tag',
    product_tag: '产品标签'

  }
};
