import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpHelper} from "../../../helper/http.helper";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class UserService {

  constructor(public http: HttpHelper,
              private httpClient: HttpClient) { }

  public login(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/users/sign_in.json', search_params)
  }

  public users(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/users.json', search_params)
  }

  public update(id: any, update_params: any): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_PUT('api/users/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {user_id: id};
    return this.http.AUTH_HTTP_DELETE('api/users/' + id + '.json', params);
  }

  public create(create_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('api/users.json', create_params);
  }

  public portrait(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/users/portrait.json', search_params)
  }

  public sso_sign_in(service_ticket: string): Observable<any> {
    return this.httpClient.get('/api/users/sso_sign_in.json?service_ticket=' + service_ticket);
  }

  public checkLogin(): Observable<any> {
    return this.httpClient.get('/api/users/check_login_status.json');
  }

}
