import { Injectable } from '@angular/core';
import {HttpHelper} from "../../helper/http.helper";
import {Observable, Subject} from "rxjs";
import {I18nService} from "../i18n/i18n.service";
import {zh_CN} from "../i18n/languages/zh_CN";
import {en_US} from "../i18n/languages/en_US";
import {I18nInterface} from "../i18n/i18n.interface";

@Injectable()
export class GlobalService {

  locale_key: { [key: string]: any } = {'zh_CN': zh_CN, 'en_US': en_US};
  locale: I18nInterface;


  constructor(public http: HttpHelper,
              public i18n: I18nService
  ) {
    this.i18n.setLocale(this.locale_key[sessionStorage.getItem('locale') || 'zh_CN']);

    this.i18n.localChange.subscribe(locale => {
      this.locale = locale;
    });
  }

  public get $current_user(): any {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    return user;
  }

  public get $eqding_user(): any {
    let user = localStorage.getItem('eqding_user');
    user = JSON.parse(user);
    return user;
  }

  public get $shop(): any {
    let shop = localStorage.getItem('shop');
    shop = JSON.parse(shop);
    return shop;
  }

  public get shop_ids(): any {
    return this.current_project && this.current_project.product_shared_by ? [this.$shop.id, this.$current_user.company.shop_id] : [this.$shop.id]
  }

  public set $shop(shop: any) {
    localStorage.setItem('shop', JSON.stringify(shop));
  }

  public set $language_system(language) {
    localStorage.setItem('language', JSON.stringify(language));
  }

  public get $language_system(): any {
    let language = localStorage.getItem('language');
    language = JSON.parse(language);
    return language;
  }

  get current_project(): any {
    const data = localStorage.getItem('current_project');
    const _current_project = JSON.parse(data);
    return _current_project;
  }

  set current_project(value: any) {
    localStorage.setItem('current_project', JSON.stringify(value));
  }

  public system_languages(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/system_languages.json', search_params)
  }

  public language_systems(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/system_languages/language_systems.json', search_params)
  }

  public deleteImage(id: string): Observable<any> {
    const params = {shop_id: id};
    return this.http.AUTH_HTTP_DELETE('api/images/' + id + '.json', params);
  }

  public ht_locations(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('api/ht_locations.json?', search_params);
  }

  get dataformat(): string {
    return sessionStorage.getItem('locale') === 'en_US' ? 'LL' : 'YYYY-MM-DD';
  }

}
