import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpHelper} from "../../../helper/http.helper";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable()
export class EqdingUserService {

  constructor(public http: HttpHelper,
              public httpClient: HttpClient) { }

  public sso_sign_in(service_ticket: string): Observable<any> {
    return this.httpClient.get('atyun/users/sso_sign_in.json?service_ticket=' + service_ticket);
  }

  public projects(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/projects.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).projects));
  }

  public update_project(id: string, update_params:any = {}): Observable<any> {
    update_params.project_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/projects/' + id + '.json', update_params);
  }

}
