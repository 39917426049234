import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "../interceptor/token.interceptor";
import { ResponseInterceptor } from "../interceptor/response.interceptor";
import { zh_CN } from "./i18n/languages/zh_CN";
import { en_US } from 'ng-zorro-antd';
export var httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
];
var ɵ0 = zh_CN, ɵ1 = en_US;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
