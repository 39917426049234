/**
 * Created by atyun on 16/7/14.
 */

export const api_config = {
  // jk: {
  apiUid: '3d5aabefe6c407a2fa4ab429534009330407d770de5e08de21c8a0cd012b0fd3',
  apiSecret: '98164d25a76fc51674b6ccc94c5cc1e0527d8d89216f0197147da896a983eaf5'
  // }

};
