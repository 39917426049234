import { I18nInterface } from '../i18n.interface';

export const en_US: I18nInterface = {
  locale: 'en_US',
  common: {
    unpin:  'Unpin',
    topping:  'Topping',
    drag_error: 'You could not drag a product-category into itself',
    role: 'Role',
    log_out: 'Exit',
    new: 'New',
    logo: 'Logo',
    name: 'Name',
    product_name: 'Product Name',
    name_en: 'English Name',
    location: 'Location',
    status: 'Status',
    phone: 'Phone',
    language: 'Language',
    create_time: 'Time',
    update_time: 'Update Time',
    option: 'Operations',
    search: 'Search',
    clear: 'Clear',
    edit: 'Edit',
    delete: 'Delete',
    upload: 'click to upload files',
    image_name: 'Please use lowercase letters(a-z) and numbers(0-9) for the name, and use the "-" between words.',
    active: 'Active',
    inactive: 'Inactive',
    state: 'State',
    country: 'Country',
    province: 'Province',
    city: 'City',
    submit: 'Submit',
    desc: 'Description',
    link: 'Link',
    page_total: 'Total',
    page_items: 'records',
    icon: 'Icon',
    image: 'Image',
    seq: 'Sequence',
    cancel: 'Cancel',
    product_attribute: 'Product Attribute',
    create: 'Create',
    details: 'Details',
    is_sku: 'is SKU Attribute',
    is_filter: 'As a filter',
    is_search: 'As a search condition',
    is_required: 'is Required',
    remove: 'Remove',
    product_attribute_value: 'Product Attribute Value',
    delete_confirm: 'Are you sure delete this data?',
    art_no: 'Art No',
    parent_art_no: 'Art No.',
    brand: 'Brand',
    picture: 'Picture',
    labels: 'Tags',
    add_child: 'Add Child',
    checking: 'Checking',
    publish: 'Publish',
    version: 'Version',
    resource: 'Resource',
    resource_name: 'Resource Name',
    child_confirm: 'Are you sure add child products?',
    username: 'Username',
    password: 'Password',
    password_placeholder: 'Please Input Password',
    real_name: 'Real name',
    import: 'Import',
    show_ordinary: 'show main-site data',
    import_product_label: 'Import Product Tags',
    import_brand: 'Import brands',
    import_product_category: 'Import product categories',
    create_success: 'Create Successful',
    update_success: 'Update Successful',
    delete_success: 'Delete Successful',
    import_success: 'Import Successful',
    create_failed: 'Something Wrong, Create Failed',
    update_failed: 'Something Wrong, Update Failed',
    delete_failed: 'Something Wrong, Delete Failed',
    edit_change_info: 'Edit category will change sku properties',
    user_center: 'Profile',
    nickname: 'Nick name',
    company: 'Company name',
    preview: 'Preview',
    site: 'Site',
    category_add_hint: 'please submit to create product category first',
    portrait: 'Portrait',
    full_name: 'Full Name',
    email: 'Email',
    name_required: 'name Required',
    sku_placeholder: 'Please choose the attribute',
    sku_status: 'Sku Status',
    delete_document_confirm: 'delete the folder will delete the documents in.',
    shop_manager: 'Manager',
    show_main_site: 'Show main-site\'s data',
    user_info: 'User',
    company_info: 'Company',
    group_info: 'Group',
    custom: 'Custom',
    set_cover: 'Set As Cover',
    cover: 'cover',
    category_type: 'Category Type',
    select: 'Select',
    input: 'Input',
    textarea: 'Textarea',
    video_type_error: 'please upload the correct type of video',
    must_input_error: 'Fields marked with * are required',
    video_specification: 'Supported video formats',
    size: 'Size',
    upload_file: 'Upload File',
    file_upload: 'File Upload',
    file_name: 'File name',
    file_type: 'File type',
    technical_parameter: 'Technical Params',
    product_category_type: 'Type',
    product: 'Product',
    promotion_gifts: 'promotion gifts',
    no_data: 'No Data',
    product_label: 'Product Tag',
    end_time: 'Expiration Date',
    batch_update: 'Batch Update',
    product_config: 'Product Config',
    share: 'Share to',
    shared_by: 'Shared by',
    yes: 'Yes',
    no: 'No',
    config: 'Config',
    role_list: "Role List",
    remark: 'Remark',
    permission: 'Permission',
    save: 'Save',
    password_error: 'Password must be input',
    password_length_error: 'Password length must in 6-20',
    username_error: 'Username must be input',
    updater: 'Updated By',
    file_not_upload_error: 'File has not been uploaded, Please wait until file uploaded.',
    show_type: 'Show Type',
    special_type: 'Special Type',
    blank: 'Blank',
    power_TD: 'Power T&D',
    description: 'Instruction',
    update: 'Update',
    file_big: 'The uploaded picture is too large. It is recommended to upload the picture with size less than 750KB'
  },
  dashboard: {
    shops: 'Shops',
    brands: 'Product Brands',
    product_labels: 'Product Tags',
    documents: 'Documents',
    product_categories: 'Product Categories',
    products: 'Products',
    user_management: 'Users',
    sites: 'Sites',
    content: 'News Center',
    classification: 'News Categories',
    news_tag: 'News Tags',
    news: 'News',
    member: 'Member Center',
    member_tag: 'Member Tags',
    member_management: 'Members',
    my_document: 'Documents',
    document_management: 'Documents',
    document_category: 'Document Categories',
    document_type: 'Document Types',
    document_language: 'Document Languages',
    document_label: 'Document Tags',
    product_center: 'Product Center',
    shop_management: 'Shop Management',
    user_center: 'User Center',
    promotion_category: 'Promotion Categories',
    promotion: 'Promotions',
    form_data: 'Form Data',
    subscribe: 'Subscribe',
    self_service: 'Self Service',
    recruitment: 'Recruitment',
    custom_contact: 'User Message',
    website_name: 'CHINT Oversea Websites',
    hide_confirm: 'Hide The Product Category In My Website',
    show_confirm: 'Show The Product Category In My Website',
    role: 'Role List',
    new_role: 'New Role',
    permission_manage: 'Permissions',
    create_role: 'Edit Role',
    edit_role: 'Create Role'
  },
  product: {
    category: 'Category',
    product_category: 'Product Category',
    product_sku: 'Product SKU',
    product_attribute: 'Product Attribute',
    edit_product_detail: 'Product Detail',
    choose_category: 'Category',
    choose_language: 'Language',
    sku_attribute: 'SKU Properties',
    edit_sku: 'Edit SKU',

    attribute_value: 'Attribute Value',
    upload: 'Upload',

    price_unit: 'Price Unit',
    price: 'Price',
    cost: 'Cost',

    weight_unit: 'Weight Unit',
    weight: 'Weight',
    package_weight: 'Package Weight',
    publish_weight: 'Publish Weight',

    length_unit: 'Length Unit',
    package_length: 'Package Length',
    package_width: 'Package Width',
    package_height: 'Package Height',

    volume: 'Volume',
    volume_weight: 'Volume Weight',

    inventory_quantity: 'Inventory Quantity',
    sales_quantity: 'Sales Quantity',
    inventory_threshold: 'Inventory Threshold',

    product_info: 'Product Info',
    product_label: "Product Tag",

    shop: 'Shop',
    image: 'Images',
    document: 'Documents',

    spu: 'SPU',
    spu_attribute: 'Attributes',
    spu_attribute_name: 'Spu attribute values',
    add_attribute: 'Add',

    product_detail: 'Overview',
    edit_document: 'Edit Document',
    new_document: 'New Document',

    previous: 'Previous',
    next: 'Next',
    save_product: 'Save',
    document_edit_error: 'there is no document at now',
    cover_hint: 'drag and drop to change the seq of images, the first image is cover',

    pending_release: 'Pending',
    selling: 'Selling',
    off_shelf: 'Off Shelf',

    price_tr: 'Price',
    inventory_tr: 'Inventory',
    weight_tr: 'Weight',
    volume_tr: 'Volume',
    product_no: 'Product No.',
    image_check: 'Recommend image size :800*800px, image format :JPEG, image size :750KB or less; Upload a maximum of 20 images',
    image_size: 'Please use lowercase letters(a-z) and numbers(0-9) for the name, and use the "-" between words.',

    edit_title: 'Product Edit',

    step_one: 'Step One',
    step_two: 'Step Two',
    step_three: 'Step Three',

    add_new_sku: 'new sku-attributes',
    product_video: 'Product Video',
    upload_video: 'Upload Video',
    click_upload_video: 'click to upload Video',
    product_video_rule: 'Video Specification',
    copy_sku: 'Copy Sku',
    copy_sku_hint: 'Copy the first row of data to other rows',
    edit_sku_image: 'Click to edit sku images',

    word_limit: 'Cannot exceed 80 characters',

    product_sample: 'Product Sample',
    certificate: 'Certificate',
    system_certification: 'System Certification',
    test_report: 'Test Report',

    promotion_title: 'Promotion',
    choose_promotion_category: 'Promotion Category',
    promotion_attribute: 'Promotion Attribute',
    edit_promotion_detail: 'Promotion Detail',
    promotion_category: 'Category',

    promotion_label: 'Promotion Tag',
    promotion_name: 'Promotion Name',
    promotion_info: 'Promotion Info',

    promotion_img: 'Promotion Images',
    promotion_document: 'Promotion Document',
    promotion_video: 'Promotion Video',
    promotion_detail: 'Promotion Detail',

    promotion_tag: 'Promotion Tag',
    product_tag: 'Product Tag'
  }
};
