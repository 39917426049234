<!--<div class="login-cms-container">-->
  <!--<div class="login-left">-->
    <!--<div class="login-logo-container">-->
      <!--<img class="login-logo" src="assets/images/login-logo.png" height="120" width="367"/>-->
      <!--<img class="login-image" src="assets/images/login-image.png" height="560" width="689"/>-->
      <!--<div style="margin-top: 50px; font-size: 12px;">-->
        <!--<span style="color: #333333;">版权所有 | 上海云深网络技术有限公司</span>-->
        <!--<a target="_blank" href="http://www.miitbeian.gov.cn">-->
          <!--<img src="assets/images/bei-icon.png" alt="" style="margin-left: 15px; width: 17px;">-->
          <!--<span style="color: #333333;">工信部备案号 沪ICP备12020720号-10</span>-->
        <!--</a>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
  <!--<div class="login-right">-->
    <!--<div class="login-form">-->
      <!--<label class="login-title">商品管理</label>-->
      <!--<div>-->
        <!--<div class="login-message-container" *ngIf="login_error">-->
          <!--<img src="assets/images/error.png"/>-->
          <!--<span class="login-message">{{error_message}}</span>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div style="margin: 30px 0 30px 0;">-->
        <!--<div class="cms-login-input">-->
          <!--<input nz-input placeholder="用户名" (keyup.enter)="sso_login()" type="text" [(ngModel)]="username">-->
        <!--</div>-->
        <!--<div class="cms-login-input">-->
          <!--<input nz-input placeholder="密码" (keyup.enter)="sso_login()" type="password" [(ngModel)]="password">-->
        <!--</div>-->
        <!--<button class="login-button" (click)="sso_login()">登录</button>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
正在跳转...
