var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { NzMessageService } from "ng-zorro-antd";
import { Router } from "@angular/router";
var _ = require('lodash');
// custom serialize encoder
var MyHttpUrlEncodingCodec = /** @class */ (function (_super) {
    __extends(MyHttpUrlEncodingCodec, _super);
    function MyHttpUrlEncodingCodec() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyHttpUrlEncodingCodec.prototype.encodeKey = function (k) {
        return encodeURIComponent(k);
    };
    MyHttpUrlEncodingCodec.prototype.encodeValue = function (v) {
        return encodeURIComponent(this.serializeValue(v));
    };
    MyHttpUrlEncodingCodec.prototype.serializeValue = function (v) {
        if (_.isObject(v)) {
            return _.isDate(v) ? v.toISOString() : JSON.stringify(v);
        }
        if (v === null || _.isUndefined(v)) {
            return "";
        }
        return v;
    };
    return MyHttpUrlEncodingCodec;
}(HttpUrlEncodingCodec));
export { MyHttpUrlEncodingCodec };
var HttpHelper = /** @class */ (function () {
    function HttpHelper(http, http_client, platformId, nz_message_service, route) {
        this.http = http;
        this.http_client = http_client;
        this.platformId = platformId;
        this.nz_message_service = nz_message_service;
        this.route = route;
    }
    Object.defineProperty(HttpHelper.prototype, "access_token", {
        get: function () {
            return isPlatformBrowser(this.platformId) ? (this._access_token || localStorage.getItem('access_token')) : '';
        },
        set: function (value) {
            this._access_token = value;
            isPlatformBrowser(this.platformId) ? localStorage.setItem('access_token', value) : '';
        },
        enumerable: true,
        configurable: true
    });
    /**
     * 获取response里的data 并且转换成JSON 并且进行status 处理
     * @param res
     * @returns {any}
     */
    HttpHelper.prototype.extractData = function (res) {
        if (res && res.status && (res.status || {}).code === "40100") {
            localStorage.clear();
            window.location.reload();
            return {};
        }
        if (res && res.status && (res.status || {}).code === "50000") {
            this.nz_message_service.info(res.status.message);
            return {};
        }
        else {
            return res.data || res || { code: res.status.code };
        }
    };
    HttpHelper.prototype.AUTH_HTTP_GET = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        var options = { headers: headers };
        return this.http_client.get(url, {
            params: new HttpParams({
                encoder: new MyHttpUrlEncodingCodec(),
                fromObject: params,
            })
        });
    };
    HttpHelper.prototype.AUTH_HTTP_PUT = function (url, body, headers) {
        if (headers === void 0) { headers = new Headers({ 'Content-Type': 'application/json' }); }
        var options = new RequestOptions({ headers: headers });
        return this.http.put(url, JSON.stringify(body), options);
    };
    HttpHelper.prototype.AUTH_HTTP_DELETE = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        return this.http_client.delete(url, {
            params: new HttpParams({
                encoder: new MyHttpUrlEncodingCodec(),
                fromObject: params
            })
        });
    };
    HttpHelper.prototype.AUTH_HTTP_POST = function (url, body, headers) {
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        var options = { headers: headers };
        return this.http_client.post(url, body, options);
    };
    HttpHelper.prototype.AUTH_HTTP_UPLOAD_PUT = function (url, body, prefix, headers) {
        if (prefix === void 0) { prefix = 'update'; }
        if (headers === void 0) { headers = new HttpHeaders(); }
        var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
        // headers.append('Content-Type', 'multipart/form-data');
        headers.set('Accept', 'application/json');
        headers.set('encrypt', 'multipart/form-data');
        var options = { headers: headers };
        var formData = this.objectToFormData(body, new FormData());
        return this.http_client.put(url, formData, options);
    };
    HttpHelper.prototype.AUTH_HTTP_UPLOAD_POST = function (url, body, prefix, headers) {
        if (prefix === void 0) { prefix = 'update'; }
        if (headers === void 0) { headers = new HttpHeaders(); }
        var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
        // headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        var options = { headers: headers };
        var formData = this.objectToFormData(body, new FormData());
        return this.http_client.post(url, formData, options);
    };
    // 将对象或数组转换成formdata的格式
    HttpHelper.prototype.objectToFormData = function (obj, form, namespace) {
        if (namespace === void 0) { namespace = ''; }
        var fd = form || new FormData();
        var formKey;
        if (obj instanceof Array) {
            for (var _i = 0, obj_1 = obj; _i < obj_1.length; _i++) {
                var item = obj_1[_i];
                if (typeof item === 'object' && !(item instanceof File)) {
                    this.objectToFormData(item, fd, namespace + "[]");
                }
                else {
                    fd.append(namespace + "[]", item);
                }
            }
        }
        else {
            for (var property in obj) {
                if (obj.hasOwnProperty(property)) {
                    if (namespace) {
                        formKey = namespace + '[' + property + ']';
                    }
                    else {
                        formKey = property;
                    }
                    // if the property is an object, but not a File,
                    // use recursivity.
                    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                        this.objectToFormData(obj[property], fd, formKey);
                    }
                    else {
                        // if it's a string or a File object
                        fd.append(formKey, obj[property]);
                    }
                }
            }
        }
        return fd;
    };
    return HttpHelper;
}());
export { HttpHelper };
